<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
    <v-form @submit.prevent="passes(login)">
      <v-container>
        <ValidationProvider vid="name" name="Nombre" rules="required" v-slot="{ errors, valid }">
          <v-text-field
            filled
            v-model="profile.name"
            append-icon=" "
            label="Nombre de Usuario"
            required
            :error-messages="errors"
            :success="valid"
            readonly
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors, valid }">
          <v-text-field
            autofocus
            filled
            v-model="profile.email"
            append-icon=" "
            type="email"
            label="Email"
            required
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider vid="current_password" name="Clave Actual" rules="required" v-slot="{ errors, valid }">
          <v-text-field
            filled
            v-model="profile.current_password"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="Clave Actual"
            counter
            @click:append="showPassword = !showPassword"
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>

        <v-row>
          <v-col cols="12" sm="6">
            <ValidationProvider vid="password" name="Clave Nueva" rules="confirmed:password_confirmation"
              v-slot="{ errors, valid }">
              <v-text-field
                filled
                v-model="profile.password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Clave Nueva"
                counter
                @click:append="showPassword = !showPassword"
                :error-messages="errors"
                :success="valid"
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" sm="6">
            <ValidationProvider vid="password_confirmation" name="Confirmar Clave Nueva" v-slot="{ errors, valid }">
              <v-text-field
                filled
                v-model="profile.password_confirmation"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Confirmar Clave Nueva"
                counter
                @click:append="showPassword = !showPassword"
                :error-messages="errors"
                :success="valid"
                ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <div align-self-center class="mt-4">
          <v-btn type="submit" @click.prevent="passes(save)" color="primary"
              dark :loading="saving">Guardar</v-btn>
        </div>
    </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    showPassword: false,
    profile: {
      name: '',
      email: '',
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  }),

  methods: {
    get: function () {
      this.loading = true

      this.$http.get('user')
      .then((response) => {
        this.profile = response.data
        this.profile.current_password = ''
        this.profile.password = ''
        this.profile.password_confirmation = ''
      })
      .catch(() => {
        this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
      })
      .then(() => {
        this.loading = false
      })
    },
    save: function () {
      this.saving = true

      this.$http.put('account', this.profile)
      .then(() => {
        this.profile.current_password = ''
        this.profile.password = ''
        this.profile.password_confirmation = ''

        this.$refs.obs.reset()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          case 422:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

            this.$refs.obs.setErrors(error.response.data.errors);

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },
  },

  created: function () {
    this.get()
  }
}
</script>
